import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/music',
    mame: 'music',
    component: () => import( /* webpackChunkName: "about" */ '../views/Music.vue')
  },
  {
    path: '/music/:id',
    mame: 'music',
    component: () => import( /* webpackChunkName: "about" */ '../views/Music.vue')
  },
  {
    path: '/video',
    mame: 'video',
    component: () => import( /* webpackChunkName: "about" */ '../views/Video.vue')
  },
  {
    path: '/video/:id',
    mame: 'video',
    component: () => import( /* webpackChunkName: "about" */ '../views/Video.vue')
  },
  {
    path: '/acc',
    mame: 'acc',
    component: () => import( /* webpackChunkName: "about" */ '../views/Acc.vue')
  },
  {
    path: '/acc/:id',
    mame: 'acc',
    component: () => import( /* webpackChunkName: "about" */ '../views/Acc.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
